import React from 'react';
import './App.css';
import api from "../AgendaTime/api";
import axios from "axios"
import apiInstance from "./api"
import Routes from './routes';
import { Helmet } from "react-helmet";
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'
import Loader from "react-loader-spinner";
import { connect } from 'react-redux'
require('bootstrap')


class App extends React.Component {
    retryCount = 0
    maxHeaderMenuItems = 9
    eventAlias = "event-1"
    landingPage = "/" //optioanl i.e. which page to load when loading from preview link
    constructor(props) {
        super(props)
        this.state = {
            ogImage: "",
            headerMenuItems: [],
            footerMenuItems: [],
            event: null,
            menuIcon: null,
            eventAlias: 'world-cup',
            loadingError: '',
            eventCountries: [],
            seoPagesData: [],
            eventNationalities: [],
            participants: [],
            socket: null,
            unReadMessageCount: 0,
            unReadMessages: [],
            userChatList: [],
            showErrorPopup: false,
            popUpErrorHeading: '',
            popUpErrorMessage: '',
            mySocketId: '',
            receivedMessagesWhileAway: [],
            unReadNotificationsCount: 0,
            allNotifications: [],
            ieError: '',
            allInvites: {
                sent: [],
                received: []
            },
            virtualMenuItems: [],
            loadingParticipants: true,
            loadingChatList: false,
            loadingInvites: false,
            allConnectedUsers: 0,
            accountReactivate: "Your Account Deletion Request is in Progress. There is Still time to Change Your Mind.",
            deleteAccountLoading: false,
            eventDetails: null,
            agendaDetails: null,
            eventSponsors: [],
            agendaTitles: []
        }
        this.allPages = []
        this.sessionExpired = false
        this.virtualMenuItems = []
        this.siteMapMenus = []
        this.certificateCompletionTime = 0
        this.browser = {}
        this.ipAddress = ""
        this.availableLocales = []
        this.isTokenFound = false
        this.defaultHeaderMenu = []
        this.backgroundInterval = null
    }
    async componentDidMount() {
        // hides all console logs on production
        this.getAgendaDetails()
        //if (process.env.NODE_ENV !== "development")
        console.log = () => { };
    }

    async getAgendaDetails() {
        api.getEventAgenda(
            "machines-can-see-2024",
            null,
            this.props.type,
            (err, data) => {
                if (err == null) {
                    console.log("agenda all data", data?.data[0]?.sessions?.data[0]?.subSessions)
                    this.setState({ agendaDetails: data?.data[0]?.sessions?.data[0]?.subSessions, agendaTitles: [data?.data[0]?.sessions?.data[0]?.subSessions[0]?.title, data?.data[0]?.sessions?.data[0]?.subSessions[1]?.title] })
                    this.getSeoData()
                    this.getEventDetails()
                    this.getEventSponsorsDetails()
                }
            }
        );
    }

    getEventDetails() {
        apiInstance.getEvent("machines-can-see-2024", async (err, data) => {
            if (err == null) {
                this.setState({ ogImage: data?.event?.social_sharing_image, menuIcon: data?.event?.logo })
                axios({
                    method: 'GET',
                    url: '/setEventData',
                    baseURL: '',
                    params: { socialSharingImage: data?.event?.social_sharing_image },
                    headers: {
                        'auth_token': '5{I~hK5&~Nb,rb}'
                    }
                }).then(resSetSeoData => {
                    console.log("og:image setted")
                }).catch(error => {

                })
            }
        })
    }

    getEventSponsorsDetails() {
        apiInstance.getEventPartners("machines-can-see-2024", null, async (err, data) => {
            if (err == null) {
                console.log("response of all partners", data?.partners)
                let temp = []
                if (data?.partners && data?.partners?.length > 0) {
                    for (let i = 0; i != data?.partners?.length; i++) {
                        if (data?.partners[i]?.sponsers?.length > 0) {
                            temp.push([...data?.partners[i]?.sponsers])
                        }
                    }
                }
                //console.log("response of all ", temp.flat())
                this.setState({ eventSponsors: temp.flat() })
            }
        })
    }

    getSeoData() {
        apiInstance.getSeoData("machines-can-see-2024", async (err, data) => {
            if (err == null) {
                for (var item in data) {
                    var keyWordString = ""
                    for (var i = 0; i < data[item].keywords.length; i++) {
                        keyWordString += data[item].keywords[i]
                        if (i < data[item].keywords.length - 1) {
                            keyWordString += ','
                        }
                    }
                    data[item].keyWordString = keyWordString
                }
                let temp = { ...this.state.seoDefaultData, ...data[0] }
                this.setState({ seoPagesData: temp })
            }
        })
    }

    componentDidUpdate(prevProps) {
    }
    render() {
        { console.log("seo data", this.state.seoPagesData, ":", this.state?.agendaTitles) }
        return (
            this.state.agendaDetails == null ?
                <div class="centered-div">
                    <Loader type="Oval" color="#F56A57" height={100} width={100} />
                </div>
                :
                <>
                    <link rel="stylesheet" href={"https://event-staging.mwancloud.com/themes/color_2.css"} />
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.seoPagesData?.seo_title || "Machines Can See 2024"}</title>
                        <meta property="description" content={this.state.seoPagesData == null ? "" : this.state.seoPagesData?.meta_description} />
                        <meta property="title" content={this.state.seoPagesData == null ? "" : this.state.seoPagesData.seo_title} />
                        <meta property="keywords" content={this.state.seoPagesData?.keywords == null ? "" : this.state.seoPagesData?.keywords} />
                        <meta property="og:title" content={this.state.seoPagesData?.title} />
                        <meta property="og:description" content={this.state.seoPagesData?.meta_description} />
                        <meta property="og:image" content={this.state?.ogImage} />
                        <meta property="og:keywords" content={this.state.seoPagesData?.keywords == null ? "" : this.state.seoPagesData?.keywords} />
                    </Helmet>
                    <Routes
                        event={this.state.eventDetails}
                        agendaDetails={this.state.agendaDetails}
                        selectedAgendaDetails={this.state.agendaDetails[0]}
                        FeaturesLoading={this.state.FeaturesLoading}
                        EventBlogs={this.state.EventBlogs}
                        Eventgallery={this.state.Eventgallery}
                        EventTestimonial={this.state.EventTestimonial}
                        EventCounter={this.state.EventCounter}
                        EventHighlight={this.state.EventHighlight}
                        countries={this.state.eventCountries}
                        nationalities={this.state.eventNationalities}
                        headerMenuItems={this.state.headerMenuItems}
                        footerMenuItems={this.state.footerMenuItems}
                        socket={this.socket}
                        allPages={this.allPages}
                        landingPage={this.landingPage}
                        mySocketId={this.state.mySocketId}
                        unReadMessageCount={this.state.unReadMessageCount}
                        unReadMessages={this.state.unReadMessages}
                        loadingParticipants={this.state.loadingParticipants}
                        loadingChatList={this.state.loadingChatList}
                        loadingInvites={this.state.loadingInvites}
                        receivedMessagesWhileAway={this.state.receivedMessagesWhileAway}
                        seoDefaultData={this.state.seoPagesData}
                        unReadNotificationsCount={this.state.unReadNotificationsCount}
                        allNotifications={this.state.allNotifications}
                        virtualMenuItems={this.state.virtualMenuItems}
                        availableLocales={this.availableLocales}
                        certificateCompletionTime={this.certificateCompletionTime}
                        onlineUsersCount={this.state.allConnectedUsers}
                        languageClick={this.onClickLanguage}
                        onChatListUpdated={() => {
                            this.setState({ receivedMessagesWhileAway: [] })
                        }}
                        onMarkChatRead={(count, id) => { }}
                        onUpdateChatList={(item, unReadCount = 0) => { }}
                        onNotificationSeen={() => { }}
                        onClearNotifications={(notificationsToRemove = [], optionalIndex = -1) => { }}
                        onFreeBookingSuccess={(showVirtualZone) => { }}
                        onResetUnReadCount={() => { }}
                        onAddNewInvite={this.OnAddNewInvite}
                        onUserLoginSuccess={this.OnUserLoggedIn}
                        onClickAccountItem={this.OnClickAccountItem}
                        menuIcon={this.state.menuIcon}
                        eventSponsors={this.state.eventSponsors}
                        agendaTitles={this.state.agendaTitles}
                    />
                </>
        );
    }

}
const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(App)
