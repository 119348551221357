import React from 'react';

export const ErrorScreen = (props)=>{
    return(
        // <div style = {{display : 'flex',flexDirection : 'column',justifyContent : 'center',alignItems : 'center'}}>
        //     <span className = "f-500" style = {{marginBottom : 15,color : '#B4B5B7',fontSize : 17}}>{props.message}</span>
        //     <div className = "btn" onClick = {props.onPressRetry}>Retry</div>
        // </div>
        <section class = "error py-3 py-md-5">
        <div class = "content container">
            <div class = "row">
                <div class = "col-12">
                    <div class = "gen-error-content text-center">
                        <img src = "/images/error-icon.svg" class = "mb-3 w-100" alt = "error-icon" />
                        {/* <h4 class = "mb-4">{props.message != null ? props.message : 'Something went wrong'}</h4> */}
                        {!props.is404 ? <p class = "mb-5">Please refresh your browser and try again or if still facing an issue <br/> please contact support at <a href = "#"  onClick = {(e)=>{
                                e.preventDefault();
                                var email = "support@mwanmobile.com";
                                var subject = "Support Mail";
                                var link = `Join the platform at _____ and you can`
                                var emailBody = `Hi there, %0D%0A have recently joined Telehealth Connect – a great new marketplace and members platform which enables the Telehealth ecosystem. %0D%0A The platform connects global professionals, organizations, and investors from the Telehealth industry and provides an ongoing information exchange and business connection service. %0D%0A ${link}: %0D%0A • 	Search and view members profiles. %0D%0A •	Chat and email with members and exhibitors directly %0D%0A •	Browse the knowledge hub’s library of videos, whitepapers and more to find information you are looking for  %0D%0A •	 Visit the virtual exhibition zone and explore solutions for your business needs`;
                                var attach = "path";
                                document.location =
                                  "mailto:" +email 
                                //   +"?subject=" + subject+"&body="+emailBody;
                        }}class = "text-dark">support@mwanmobile.com</a></p> : null}
                        <a style = {{cursor : 'pointer'}} class = "error_btn" onClick = {props.onPressRetry}> 
                            {/* <i class="fa fa-refresh"></i> */}
                            {props.is404 ? "Home":"Refresh"} 
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </section>	
    )
}