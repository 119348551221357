import axios from '../axios/index'
import { Constants } from '../../Constants';
import utility from '../../Utility/utility';

export default {
    getEvent(eventAlias, callback) {
        axios.get(`${eventAlias}/getEvent`,
            {
                params: { isPreview: 1 },
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': "rlliGq0LQnMFHYtHVo98sXdHCbps0rjmoep4GmTedlbhJ7revdNYhWBUZ67XYY6G"  ///   stagging token
                    //'apiToken': "C0ElwWj4Y2s7LZWMFHQ5Y980MPeFVZQzAO8cdUJzcYJkfsMP4AU3LRRFxc5mkzbE"  /// live token
                }
            }).then(response => {
                callback(null, response.data)
            }).catch(error => {
                callback(error, null)
            })
    },
    async getEventMenu(eventAlias = 'world-cup', type = 'Header', callback) {
        await axios.get(`${eventAlias}/getEventMenu?type=${type}`,
            {
                params: { isActive: 1, isPreview: Constants.isPreview },
                headers: {
                    'apiToken': Constants.API_TOKEN,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                callback(null, response.data)

            }).catch(error => {
                callback(error, null)
            });
    },

    getEventPartners(eventAlias, type, callback) {
        axios.get(`${eventAlias}/getEventPartners`,
            {
                params: { type: type, isActive: 1, isPreview: Constants.isPreview },
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN
                }
            }).then(response => {
                callback(null, response.data)
            }).catch(error => {
                callback(error, null)
            })
    },

    async getAllFeatures(eventAlias, callback) {
        await axios.get(`${eventAlias}/getAllFeatures`,
            {
                params: { isActive: 1, isPreview: Constants.isPreview },
                headers: {
                    'apiToken': Constants.API_TOKEN,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                callback(null, response.data)

            }).catch(error => {
                callback(error, null)
            });
    },
    getEventCountries(eventAlias = 'world-up', callback) {
        axios.get(`${eventAlias}/getEventCountry`,
            {
                params: { isPreview: Constants.isPreview },
                headers: { 'Content-Type': 'application/json', 'apiToken': Constants.API_TOKEN },
            }).then(response => {
                callback(null, response.data)
            }).catch(error => {
                callback(error, null)
            });
    },
    getSeoData(eventAlias, callback) {
        console.log("going to call seo")
        axios.get(`${eventAlias}/myDefaultPageSeo`,
            {
                'Content-Type': 'application/json',
                headers: { 'apiToken': Constants.API_TOKEN },
            }).then(response => {
                console.log("going to call seo", response)
                axios({
                    method: 'GET',
                    url: '/setSeoData',
                    baseURL: '',
                    params: { seoData: JSON.stringify(response.data.page) },
                    headers: {
                        'auth_token': '5{I~hK5&~Nb,rb}'
                    }
                }).then(resSetSeoData => {

                }).catch(error => {

                })
                setTimeout(() => {
                    fetch('/home').then(resFetch => {

                    }).catch(error => {

                    })
                }, 0);

                callback(null, response.data.page)
            }).catch(error => {
                callback(error, null)
            });
    },
    async getUnReadMessages(eventAlias, token, callback) {
        await axios.get(`${eventAlias}/getUserUnreadChat`,
            {
                params: { isPreview: Constants.isPreview },
                headers:
                {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.data.status == "success") {
                    var count = 0
                    for (var i = 0; i < response.data.userIds.length; i++) {
                        count += response.data.userIds[i].count
                    }
                    callback(count, response.data.userIds)
                } else {
                    if (response.data.errorCode == 440) {
                        callback(-1)
                    }
                }
            }).catch(error => {
                callback(0)
            });
    }, getUserChatList(eventAlias, token, callback) {
        axios.get(`${eventAlias}/getUserChatList`,
            {
                params: { isPreview: Constants.isPreview },
                headers:
                {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.data.status == "success") {
                    for (var i = 0; i < response.data.userIds.length; i++) {
                        let string = response.data.userIds[i].lastTime.replace(" ", "T")
                        response.data.userIds[i].lastTime = new Date(string)
                    }
                    callback(response.data.userIds)
                } else {
                    callback([])
                }
            }).catch(error => {
                callback([])
            });
    },
    async getUserBookingStatus(eventAlias, token, callback) {
        await axios.get(`${eventAlias}/getUserPremiumBookingStatus`,
            {
                params: { isPreview: Constants.isPreview },
                headers:
                {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.data.status == "success") {
                    callback(null, response.data.isBooking)
                } else {
                    callback(-1)
                }
            }).catch(error => {
                callback(-1)
            });
    },
    async getUserNotifications(eventAlias, token, callback) {
        await axios.get(`${eventAlias}/notifications/all`,
            {
                params: { isPreview: Constants.isPreview },
                headers:
                {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                },
            }).then(response => {
                if (response.data.status == "success") {
                    var notifications = response.data.notifications.map(item => {
                        var date = utility.convertUTCDateToLocalDate(new Date(item.created_at))
                        return {
                            title: item.title,
                            body: item.body,
                            action_url: item.action_url,
                            is_external_link: item.is_external_link,
                            time: utility.convertUTCDateToLocalDate(new Date(item.created_at)),
                            timeString: utility.calculateTimeString(utility.convertUTCDateToLocalDate(new Date(item.created_at)))
                        }
                    })
                    callback(null, notifications, response.data.total)
                } else {
                    callback({ message: 'Error' })
                }
            }).catch(error => {
                callback({
                    message: 'Something went wrong'
                }, null)
            });
    },
    markNotificationsRead(eventAlias, data, token, callback) {
        axios.post(`${eventAlias}/notifications/read`,
            data,
            {
                params: { isPreview: Constants.isPreview },
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.data.status == 'success') {
                    callback(null, 'Deleted successfully')
                }
                else {
                    callback('Error deleting', null)
                }
            }).catch(error => {
                callback({
                    message: 'Something went wrong'
                }, null)
            })
    },
    deleUserNotification(eventAlias, data, token, callback) {
        axios.post(`${eventAlias}/notifications/delete`,
            data,
            {
                params: { isPreview: Constants.isPreview },
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN,
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.data.status == 'success') {
                    callback(null, 'Deleted successfully')
                }
                else {
                    callback('Error deleting', null)
                }
            }).catch(error => {
                callback({
                    message: 'Something went wrong'
                }, null)
            })
    },
    getClientCountry(callback) {
        axios.get('https://extreme-ip-lookup.com/json/', { params: { key: 'IbtXDq9ApA75FkAGdkKP' } }).then(response => {
            var obj = {
                ip: response.data.query,
                country: response.data.countryCode
            }
            callback(obj)
        }).catch(err => {
            callback(null)
        })
    },
    saveErrorLog(eventAlias, data, callback) {
        axios.post(`${eventAlias}/frontEndErrorLogs`,
            data,
            {
                params: { isPreview: Constants.isPreview },
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN
                }
            }).then(response => {
                if (response.data.status == 'success') {
                    callback('Error data saved')
                }
                else {
                    callback('Something went wrong')
                }
            }).catch(error => {
                callback('Something went wrong')
            })
    },
    logoutUser(jwtToken) {
        axios({
            url: '/users/auth',
            baseURL: process.env.REACT_APP_JOBFAIR_API_ENDPOINT,
            method: 'DELETE',
            headers: { "Content-Type": "application/json", Authorization: jwtToken }
        }).then(response => {

        }).catch(error => {
        })
    },
    saveFCMToken(eventAlias, data) {
        return axios.post(`${eventAlias}/webpush/subscriptions`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'apiToken': Constants.API_TOKEN
                }
            })
    },
}