let initialState = {
    blogdata:[],
    pageTitle:"Page 1"
}
const sepratePageBlog = (state = initialState, action) => {
    switch (action.type) {
        case 'BLOG_LOADED' : 
            return{
            ...state,
            blogdata : action.payload.features,
            pageTitle:action.payload.title
            }
        default:
            return state
        }
  }
  export default sepratePageBlog