export const LOADING = "LOADING"
export const DATATYPES = "DATATYPES"
export const CONTENT = "CONTENT"
export const FILTERS = "FILTERS"
export const VIDEOS = "VIDEOS"
export const DOWNLOADING = "DOWNLOADING"
export const DOWNLOADRES = "DOWNLOADRES"
export const FILTER_ID = "FILTER_ID"
export const FORUM_DATA = "FORUM_DATA"
export const SAVE_FORUM = "SAVE_FORUM"
export const FORUMLOADING = "FORUMLOADING"
export const DELETE_FORUM = "DELETE_FORUM"
export const SAVE_FORUM_MESSAGE = "SAVE_FORUM_MESSAGE"
export const DELETE_FORUM_MESSAGE = "DELETE_FORUM_MESSAGE"
export const FORUMLOADING_MSG = "FORUMLOADING_MSG"