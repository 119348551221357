import { isSafari } from "react-device-detect";
import axios from "../components/axios";
import { Constants } from "../Constants";
const { detect } = require("detect-browser");
export var monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export var monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export var weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const assignCheck = () => {
  if (typeof Object.assign != "function") {
    Object.assign = function (target) {
      "use strict";
      if (target == null) {
        throw new TypeError("Cannot convert undefined or null to object");
      }

      target = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source != null) {
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
      }
      return target;
    };
  }
};
export default {
  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  validatePhone(phoneNumber) {
    phoneNumber = phoneNumber
      .replace("-", "")
      .replace("(", "")
      .replace(")", "")
      .replace(/\s/g, "");
    if (phoneNumber.indexOf("+") == 0) {
      if (phoneNumber.length < 8) {
        return false;
      }
      if (phoneNumber.length > 19) {
        return false;
      }
    }
    if (phoneNumber.length < 7) {
      return false;
    }
    if (phoneNumber.length > 18) {
      return false;
    }
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,30}$/;
    return regex.test(String(phoneNumber).toLocaleLowerCase());
  },
  validateUrl(url) {
    var regex =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return regex.test(String(url).toLocaleLowerCase());
  },
  validateYoutubeUrl(url) {
    var regex =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    return regex.test(String(url).toLocaleLowerCase());
  },
  searchTree(element, matchingTitle = "", matchingHref = "") {
    let matchValue = matchingTitle != "" ? matchingTitle : matchingHref;
    var checkAgainstType = "";
    if (matchingTitle != "") {
      checkAgainstType = "title";
    }
    if (matchingHref != "") {
      checkAgainstType = "href";
    }
    if (element[checkAgainstType] == matchValue) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(
          element.children[i],
          matchingTitle,
          matchingHref
        );
      }
      return result;
    }
    return null;
  },
  getLoggedInUser(eventAlias) {
    var userInfoString = localStorage.getItem(
      Constants.API_TOKEN + eventAlias + "user_info"
    );
    return JSON.parse(userInfoString);
  },
  validPassFormat(pass) {
    return pass.length > 7;
  },
  validName(name) {
    var regex = /^[A-z\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-_`~()]+$/;
    return regex.test(name);
  },
  convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    var ua = navigator.userAgent.toLowerCase();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    if (isSafari) {
      offset = 0;
    }
    newDate.setHours(hours - offset);

    return newDate;
  },
  getDateString(date) {
    var _date = new Date(date);
    return (
      _date.getDate() +
      " " +
      monthNames[_date.getMonth()] +
      " " +
      _date.getFullYear()
    );
  },
  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  slotStartTimeInSeconds(slot) {
    var givenSlotStartTime = slot.split("-")[0];
    var givenSlotStartSplit = givenSlotStartTime.split(" ");
    let hour = parseInt(givenSlotStartSplit[0]);
    var givenSlotStartTimeHours =
      hour +
      (givenSlotStartSplit[3].toLowerCase() == "am"
        ? hour == 12
          ? -12
          : 0
        : hour != 12
        ? 12
        : 0);
    var givenSlotStartTimeMinutes = parseInt(givenSlotStartSplit[2]);
    return givenSlotStartTimeHours * 3600 + givenSlotStartTimeMinutes * 60;
  },
  slotEndTimeInSeconds(slot) {
    var givenSlotEndTime = slot.split("-")[1];
    var givenSlotEndSplit = givenSlotEndTime.split(" ");
    let hour = parseInt(givenSlotEndSplit[0]);
    var givenSlotEndTimeHours =
      hour +
      (givenSlotEndSplit[3].toLowerCase() == "am"
        ? hour == 12
          ? -12
          : 0
        : hour != 12
        ? 12
        : 0);
    var givenSlotEndTimeMinutes = parseInt(givenSlotEndSplit[2]);
    return givenSlotEndTimeHours * 3600 + givenSlotEndTimeMinutes * 60;
  },
  checkSlotAvailiblityForUser(givenSlot, allSlots) {
    var givenSlotStartTimeInSecs = this.slotStartTimeInSeconds(givenSlot);
    let noSlotFound = true;
    for (var i = 0; i < allSlots.length; i++) {
      var startTime = allSlots[i].time.split("-")[0];
      var endTime = allSlots[i].time.split("-")[1];
      var startTimeSplit = startTime.split(" ");
      var endTimeSplit = endTime.split(" ");
      let hour = parseInt(startTimeSplit[0]);
      var startTimeHours =
        hour +
        (startTimeSplit[3].toLowerCase() == "am"
          ? hour == 12
            ? -12
            : 0
          : hour != 12
          ? 12
          : 0);
      var startTimeMinutes = parseInt(startTimeSplit[2]);
      var startTimeInSecs = startTimeHours * 3600 + startTimeMinutes * 60;
      hour = parseInt(endTimeSplit[0]);
      var endTimeHours =
        hour +
        (endTimeSplit[3].toLowerCase() == "am"
          ? hour == 12
            ? -12
            : 0
          : hour != 12
          ? 12
          : 0);
      var endTimeMinutes = parseInt(endTimeSplit[2]);

      var endTimeInSecs = endTimeHours * 3600 + endTimeMinutes * 60;

      if (
        givenSlotStartTimeInSecs >= startTimeInSecs &&
        givenSlotStartTimeInSecs <= endTimeInSecs
      ) {
        noSlotFound = false;
        return true;
      } else {
        continue;
      }
    }
    if (noSlotFound) {
      return false;
    }
  },
  calculateZoneSpecificTime(date = "", offset = null) {
    // create Date object for current location
    var d = new Date();
    let dateHasOffsetInfo = true;
    if (date != "") {
      d = new Date(date);
      dateHasOffsetInfo = false;
    }
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    var utc = d.getTime();
    if (dateHasOffsetInfo) {
      utc = utc + d.getTimezoneOffset() * 60000;
    }
    // create new Date object for different city
    // using supplied offset
    var nd = null;
    if (offset == null) {
      nd = new Date(utc - d.getTimezoneOffset() * 60 * 1000);
    } else {
      nd = new Date(utc + 3600000 * offset);
    }

    // nd = new Date()
    // return time as a string
    return nd;
  },
  getTimezoneOffset() {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? "+" : "-";
    offset = Math.abs(offset);
    return sign + z((offset / 60) | 0) + z(offset % 60);
  },
  mobileCheck() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  extractStringFromElement(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  },
  calculateTimeString(time) {
    let currentDate = new Date();
    let timeString = "";
    let timeDifferenceSeconds = (currentDate.getTime() - time.getTime()) / 1000;
    let timeDifferenceMinutes = Math.floor(timeDifferenceSeconds / 60);
    let timeDifferenceHours = Math.floor(timeDifferenceSeconds / 3600);
    let timeDifferenceDays = Math.floor(timeDifferenceHours / 24);
    if (timeDifferenceSeconds < 60) {
      if(timeDifferenceSeconds < 10){
        timeString = "Now"
      }else{
      timeString = Math.abs(Math.floor(timeDifferenceSeconds)) + " secs ago";
      }
    } else if (timeDifferenceMinutes < 60) {
      timeString = timeDifferenceMinutes + " mins ago";
    } else if (timeDifferenceHours < 24) {
      timeString = timeDifferenceHours + " hours ago";
    } else {
      let dayDiff = currentDate.getDay() - timeDifferenceDays;
      if (timeDifferenceHours / 24 > 1) {
        let date = new Date(time);
        timeString =
          date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
      } 
      else if(timeDifferenceHours / 24 == 1){
        timeString = "Yesterday";
      }
      else {
        // if(timeDifferenceDays == 1){
        //     timeString = "Yesterday"
        // }else{
        //     timeString = weekDays[dayDiff]
        // }
        timeString = "Yesterday";
      }
    }
    return timeString;
  },
  getYoutubeVideoId(url) {
    if (url == null) {
      return null;
    }
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  },
  extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  },
  isIE() {
    var ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
  },
  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  getBrowserInfo() {
    const browser = detect();
    if (browser) {
      return {
        name: browser.name,
        version: browser.version,
        os: browser.os,
      };
    }
    return {};
  },
  async initiatePictureInPictureApi(videoTag) {
    if (document.pictureInPictureEnabled) {
      const video = document.getElementById(videoTag);
      if (!document.pictureInPictureElement) {
        await video.requestPictureInPicture();
      } else {
        await document.exitPictureInPicture();
      }
      video.addEventListener("enterpictureinpicture", (event) => {
        console.log("Video entered Picture-in-Picture mode");
      });
      video.addEventListener("leavepictureinpicture", () => {
        console.log("Video left Picture-in-Picture mode");
      });
    }
  },
  getJobBoardAccessStatus(type) {
    let allowedTypes = ["jobSeeker", "companyModerator", "companyOwner"];
    return allowedTypes.indexOf(type) > -1;
  },
  comparingUserSetTime(status, duration) {
    if (status != null) {
      if (status == "1" && duration != null) {
        var userSetTime = duration.split("-");
        var userDate = new Date();
        var year = userDate.getFullYear();
        var month = userDate.getMonth();
        var date = userDate.getDate();
        var hours =
          userDate.getHours() > 12
            ? userDate.getHours() % 12
            : userDate.getHours();
        var ampm = userDate.getHours() > 12 ? "PM" : "AM";
        var minute = userDate.getMinutes();
        var currentDate = `${month}/${date}/${year} ${
          hours > 10 ? hours : "0" + hours
        }:${minute < 10 ? "0" + minute : minute} ${ampm}`;
        var startTime = `${month}/${date}/${year} ${userSetTime[0]}`;
        var endTime = `${month}/${date}/${year} ${userSetTime[1]}`;
        var currentTime1 = new Date(currentDate).getTime();
        var startTime1 = new Date(startTime).getTime();
        var endTime1 = new Date(endTime).getTime();
        if (
          startTime.split(" ").includes("PM") &&
          endTime.split(" ").includes("AM")
        ) {
          const currentHours = new Date(currentDate).getHours();
          const startHours = new Date(startTime).getHours();
          const endHours = new Date(endTime).getHours();
          if (startHours >= 12 && endHours <= 12 && currentHours != 0) {
            if (
              currentTime1 > startTime1 &&
              currentTime1 > endTime1 &&
              startTime1 > endTime1
            ) {
              return "online";
            } else if (
              currentTime1 < startTime1 &&
              currentTime1 < endTime1 &&
              startTime1 > endTime1
            ) {
              return "online";
            } else {
              return "offline";
            }
          } else {
            return "offline";
          }
        } else {
          if (
            startTime.split(" ").includes("AM") &&
            endTime.split(" ").includes("AM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 0 && endHours == 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online";
              } else {
                return "offline";
              }
            } else if (startHours >= 0 && endHours >= 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 <= endTime1
              ) {
                return "online";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                currentHours == 0 &&
                startTime1 >= endTime1
              ) {
                return "online";
              } 
              // New Code start Here
              else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "offline";
              }
              // End Here
              else {
                return "offline";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "online";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "online";
              } else {
                return "offline";
              }
            }
          } else if (
            startTime.split(" ").includes("PM") &&
            endTime.split(" ").includes("PM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 12 && endHours >= 12) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1
              ) {
                return "online";
              } else {
                return "offline";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "online";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "online";
              } else {
                return "offline";
              }
            }
          } else {
            var currentTime = new Date(currentDate).getTime();
            var userStartDate = new Date(startTime).getTime();
            var userEndDate = new Date(endTime).getTime();
            //New Code Here
            var userEndHour = new Date(endTime).getHours();
            var currentHour = new Date(currentDate).getHours()
            // End HERE
            if (currentTime > userStartDate && currentTime < userEndDate) {
              return "online";
            } else if (
              currentTime < userStartDate &&
              currentTime > userEndDate
            ) {
              return "online";
            } 
            //New Code Here
              else if(currentTime < userStartDate && currentTime < userEndDate && currentHour == 0 && userEndHour == 12 && userStartDate < userEndDate){
                return "offline"
              }
              else if(currentTime < userStartDate && currentTime < userEndDate && userEndHour > 0 && userStartDate < userEndDate){
                return "online"
              }
              // End Here
            else {
              return "offline";
            }
          }
        }
      } else if (status == "1" && duration == null) {
        return "online";
      } else if (status == "2" && duration == null) {
        return "invisible-status";
      } else {
        return "offline";
      }
    } else {
      return "online";
    }
  },
  checkUserStatus(status, duration) {
    if (status != null) {
      if (status == "1" && duration != null) {
        var userSetTime = duration.split("-");
        var userDate = new Date();
        var year = userDate.getFullYear();
        var month = userDate.getMonth();
        var date = userDate.getDate();
        var hours =
          userDate.getHours() > 12
            ? userDate.getHours() % 12
            : userDate.getHours();
        var ampm = userDate.getHours() > 12 ? "PM" : "AM";
        var minute = userDate.getMinutes();
        var currentDate = `${month}/${date}/${year} ${
          hours > 10 ? hours : "0" + hours
        }:${minute < 10 ? "0" + minute : minute} ${ampm}`;
        var startTime = `${month}/${date}/${year} ${userSetTime[0]}`;
        var endTime = `${month}/${date}/${year} ${userSetTime[1]}`;
        var currentTime1 = new Date(currentDate).getTime();
        var startTime1 = new Date(startTime).getTime();
        var endTime1 = new Date(endTime).getTime();
        if (
          startTime.split(" ").includes("PM") &&
          endTime.split(" ").includes("AM")
        ) {
          const currentHours = new Date(currentDate).getHours();
          const startHours = new Date(startTime).getHours();
          const endHours = new Date(endTime).getHours();
          if (startHours >= 12 && endHours <= 12 && currentHours != 0) {
            if (
              currentTime1 > startTime1 &&
              currentTime1 > endTime1 &&
              startTime1 > endTime1
            ) {
              return "Online";
            } else if (
              currentTime1 < startTime1 &&
              currentTime1 < endTime1 &&
              startTime1 > endTime1
            ) {
              return "Online";
            } else {
              return "Offline";
            }
          } else {
            return "Offline";
          }
        } else {
          if (
            startTime.split(" ").includes("AM") &&
            endTime.split(" ").includes("AM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 0 && endHours == 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "Online";
              } else {
                return "Offline";
              }
            } else if (startHours >= 0 && endHours >= 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "Online";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 <= endTime1
              ) {
                return "Online";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                currentHours == 0 &&
                startTime1 >= endTime1
              ) {
                return "Online";
              } 
              else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "Offline";
              }
              else {
                return "Offline";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "Online";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "Online";
              } else {
                return "Offline";
              }
            }
          } else if (
            startTime.split(" ").includes("PM") &&
            endTime.split(" ").includes("PM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 12 && endHours >= 12) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "Online";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "Online";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1
              ) {
                return "Online";
              } else {
                return "Offline";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "Online";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "Online";
              } else {
                return "Offline";
              }
            }
          } else {
            var currentTime = new Date(currentDate).getTime();
            var userStartDate = new Date(startTime).getTime();
            var userEndDate = new Date(endTime).getTime();
            var userEndHour = new Date(endTime).getHours();
            var currentHour = new Date(currentDate).getHours()
            if (currentTime > userStartDate && currentTime < userEndDate) {
              return "Online";
            } 
            else if (
              currentTime < userStartDate &&
              currentTime > userEndDate
            ) {
              return "Online";
            } 
            else if(currentTime < userStartDate && currentTime < userEndDate && currentHour == 0 && userEndHour == 12 && userStartDate < userEndDate){
              return "Offline"
            }
            else if(currentTime < userStartDate && currentTime < userEndDate && userEndHour > 0 && userStartDate < userEndDate){
              return "Online"
            }
            
            else {
              return "Offline";
            }
          }
        }
      } else if (status == "1" && duration == null) {
        return "Online";
      } else if (status == "2" && duration == null) {
        return "Invisible";
      } else {
        return "Offline";
      }
    } else {
      return "Online";
    }
  },
  chatIcon(status, duration, online=false,id = null,data=[]) {
    var Online = online
    if(Online == false && data.length>0){
      let onlineParticipent = []
      data.map(item=>{
        onlineParticipent.push(item.userId)
      })
      if(onlineParticipent.includes(id)){
        Online = true
      }
      else{
        Online =  false
      }
    }
    if (status != null && Online ==  true) {
      if (status == "1" && duration != null) {
        var userSetTime = duration.split("-");
        var userDate = new Date();
        var year = userDate.getFullYear();
        var month = userDate.getMonth();
        var date = userDate.getDate();
        var hours =
          userDate.getHours() > 12
            ? userDate.getHours() % 12
            : userDate.getHours();
        var ampm = userDate.getHours() > 12 ? "PM" : "AM";
        var minute = userDate.getMinutes();
        var currentDate = `${month}/${date}/${year} ${
          hours > 10 ? hours : "0" + hours
        }:${minute < 10 ? "0" + minute : minute} ${ampm}`;
        var startTime = `${month}/${date}/${year} ${userSetTime[0]}`;
        var endTime = `${month}/${date}/${year} ${userSetTime[1]}`;
        var currentTime1 = new Date(currentDate).getTime();
        var startTime1 = new Date(startTime).getTime();
        var endTime1 = new Date(endTime).getTime();
        if (
          startTime.split(" ").includes("PM") &&
          endTime.split(" ").includes("AM")
        ) {
          const currentHours = new Date(currentDate).getHours();
          const startHours = new Date(startTime).getHours();
          const endHours = new Date(endTime).getHours();
          if (startHours >= 12 && endHours <= 12 && currentHours != 0) {
            if (
              currentTime1 > startTime1 &&
              currentTime1 > endTime1 &&
              startTime1 > endTime1
            ) {
              return "online-chat";
            } else if (
              currentTime1 < startTime1 &&
              currentTime1 < endTime1 &&
              startTime1 > endTime1
            ) {
              return "online-chat";
            } else {
              return "offline-chat";
            }
          } else {
            return "offline-chat";
          }
        } else {
          if (
            startTime.split(" ").includes("AM") &&
            endTime.split(" ").includes("AM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 0 && endHours == 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online-chat";
              } else {
                return "offline-chat";
              }
            } else if (startHours >= 0 && endHours >= 0) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online-chat";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 <= endTime1
              ) {
                return "online-chat";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                currentHours == 0 &&
                startTime1 >= endTime1
              ) {
                return "online-chat";
              } 
              // New Code start Here
              else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "offline-chat";
              } else {
                return "offline-chat";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "online-chat";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "online-chat";
              } else {
                return "offline-chat";
              }
            }
          } else if (
            startTime.split(" ").includes("PM") &&
            endTime.split(" ").includes("PM")
          ) {
            const currentHours = new Date(currentDate).getHours();
            const startHours = new Date(startTime).getHours();
            const endHours = new Date(endTime).getHours();
            if (startHours >= 12 && endHours >= 12) {
              if (
                currentTime1 >= startTime1 &&
                currentTime1 >= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online-chat";
              } else if (
                currentTime1 <= startTime1 &&
                currentTime1 <= endTime1 &&
                startTime1 >= endTime1
              ) {
                return "online-chat";
              } else if (
                currentTime1 >= startTime1 &&
                currentTime1 <= endTime1
              ) {
                return "online-chat";
              } else {
                return "offline-chat";
              }
            } else {
              var currentTime = new Date(currentDate).getTime();
              var userStartDate = new Date(startTime).getTime();
              var userEndDate = new Date(endTime).getTime();
              if (currentTime > userStartDate && currentTime < userEndDate) {
                return "online-chat";
              } else if (
                currentTime < userStartDate &&
                currentTime > userEndDate
              ) {
                return "online-chat";
              } else {
                return "offline-chat";
              }
            }
          } else {
            var currentTime = new Date(currentDate).getTime();
            var userStartDate = new Date(startTime).getTime();
            var userEndDate = new Date(endTime).getTime();
            var userEndHour = new Date(endTime).getHours();
            var currentHour = new Date(currentDate).getHours()
            if (currentTime > userStartDate && currentTime < userEndDate) {
              return "online-chat";
            } else if (
              currentTime < userStartDate &&
              currentTime > userEndDate
            ) {
              return "online-chat";
            } 
            else if(currentTime < userStartDate && currentTime < userEndDate && currentHour == 0 && userEndHour == 12 && userStartDate < userEndDate){
              return "offline-chat"
            }
            else if(currentTime < userStartDate && currentTime < userEndDate && userEndHour > 0 && userStartDate < userEndDate){
              return "online-chat"
            }
            else {
              return "offline-chat";
            }
          }
        }
      } else if (status == "1" && duration == null) {
        return "online-chat";
      } else if (status == "2" && duration == null) {
        return "hidden-chat";
      } else {
        return "offline-chat";
      }
    } else {
      return "offline-chat";
    }
  },
};
export const getPaginatedData = (data, currentPage, dataLimit) => {
  const startIndex = currentPage * dataLimit - dataLimit;
  const endIndex = startIndex + dataLimit;
  return data.slice(startIndex, endIndex);
};
export const getPaginationGroup = (totalCount, dataLimit, currentPage) => {
  // let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
  // return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  let pageCount = Math.ceil(totalCount / dataLimit);
  return new Array(pageCount).fill().map((_, idx) => idx + 1);
};
export const filterSearch = (searchString, array, keys) => {
  let results = array.filter(function (item) {
    for (let i = 0; i < keys.length; i++) {
      let elem = item.details[keys[i]];
      if (elem) {
        if (elem.toString().toLowerCase().includes(searchString.toLowerCase()))
          return true;
      }
      return false;
    }
    return false;
  });
  return results;
};
export const downloadURI = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const countNumberOfUser = (data) => {
  let numberOfUserCount = [];
  data.map((item) => {
    if (!numberOfUserCount.includes(item.user_id)) {
      numberOfUserCount.push(item.user_id);
    }
  });
  return numberOfUserCount.length;
};

export const forumfullName = (id, data) => {
  if (data != null) {
    let forumCreator = data.find((item) => item.user_id == id);
    if (forumCreator != undefined) {
      return forumCreator.user.firstName + " " + forumCreator.user.lastName;
    }
  }
};

export const wordsRestrictor = (text, limit) => {
  if(text != null && text != undefined ){
  if (text.split(" ").length > limit) {
    return text.split(" ").slice(0, limit).join(" ") + " ...";
  } else {
    return text;
  }
}else{
  return text
}
};
export const getprofileImage = (id, data) => {
  if (data != null) {
    let forumCreator = data.find((item) => item.user_id == id);
    console.log(forumCreator);
    if (forumCreator != undefined) {
      return forumCreator.user.profileImageUrl;
    } else {
      return null;
    }
  }
};

export const dateExctrator = (update) => {
  var date = new Date(update.split(' ')[0].replace(/-/g, "/"))
  return date.getDate() + ' ' +  date.toLocaleString('en-us', { month: 'short' }) +" "+ date.getFullYear()

}

export const jobBoardNavigator = (basepath='',custompath,alias='',target='_self',user = null,path = "")=>{
  let selectedLocale = localStorage.getItem("language"+alias) || 'en';
  var hash = localStorage.getItem('login_hash')
  let location =  custompath+"?hash="+hash+"&URI="
  +window.location.origin+"&alias="+alias+"&locale="+selectedLocale
  let BASE_url = new URL(custompath)
  let customurl = new URL(basepath)
  if(user != null){
  if(user.userRole != "boothRepresentative"  && user.userRole != "organizer"){
  if(customurl.hostname == BASE_url.hostname ){
    if(path ==="") { 
      window.open(location,target)
     }
    else{ 
      window.open(location+"&route="+path,target)
    }
    }
  else{
    window.open(custompath,target)
  }
  }else{
    if(customurl.hostname != BASE_url.hostname ){
      window.open(custompath,target)
     }
    
  }
  
}else{
  window.open(custompath,target)
}
}
