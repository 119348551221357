import * as Actions from "../actionTypes";
const initialState = {
    content: null,
    loading: false,
    vidoes :[],
    loadingContent: false,
    downloading: false,
    filters: null,
    loadingFilters: false,
    dataTypes:[],
    fileFormatId:null,
    forumdata:[],
    loadingSaveForum:false,
    forumMessageData:[],
    deleteMsgLoading:false,
    knowledgehubHeaderdata:null,
    knowlegeHubTypes:[]
  };
const marketPlaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOADING+Actions.DATATYPES : {
            return{
                ...state,
                loading : true
            }
        }
        case 'KNOWLEDGEHUBVIDE':{
            return {
                ...state,
                loading:true
            }
        }
        case Actions.LOADING+Actions.CONTENT : {
            return{
                ...state,
                loadingContent : true,
                // loading:true
            }
        }
        case Actions.LOADING+Actions.FILTERS: {
            return{
                ...state,
                loadingFilters : true
            }
        }
        case Actions.DOWNLOADING: {
            return{
                ...state,
                downloading : true
            }
        }
        case Actions.CONTENT : {
            return{
                ...state,
                loadingContent : false,
                content: action.payload
            }
        }
        case Actions.FILTERS: {
            return{
                ...state,
                loadingFilters : false,
                filters: action.payload
            }
        }
        case Actions.DOWNLOADRES: {
            return{
                ...state,
                downloading : false
            }
        }
        case Actions.DATATYPES:{
            return {
                ...state,
                loading:false,
                dataTypes: [...action.payload]
            }
        }
        case Actions.FORUM_DATA:{
            console.log(action.payload)
            return {
                ...state,
                loading:false,
                forumdata: [...action.payload]
            }
        }
        case Actions.VIDEOS:{
            return {
                ...state,
                loading:false,
                videos:[...action.payload]
            }
        }
        case Actions.FILTER_ID:{
            return {
                ...state,
                fileFormatId:action.payload

            }
        }
        case Actions.SAVE_FORUM:{
            return {
                ...state,
                loadingSaveForum:false,
                forumdata:[...action.payload],
                forumMessageData:[...action.payload]

                

            }
        }
        case Actions.SAVE_FORUM_MESSAGE:{
            return {
                ...state,
                loadingSaveForum:false,
                forumMessageData:[...action.payload],
                forumdata: [...action.payload]
                

            }
        }
        case Actions.FORUMLOADING : {
            console.log(action.type)
            return{
                ...state,
                loadingSaveForum : true
            }
        }
        case Actions.DELETE_FORUM : {
            return{
                ...state,
                loadingSaveForum : false,
                forumdata:[...action.payload],
                forumMessageData:[...action.payload]
            }
        }
        case Actions.DELETE_FORUM_MESSAGE:{
            return {
                ...state,
                deleteMsgLoading:false,
                forumdata:[...action.payload],
                forumMessageData:[...action.payload]

            }
        }
        case 'KNOWLEDGEHUBHEADER':{
            return {
                ...state,
                knowledgehubHeaderdata:action.payload}
        }
        case 'KNOWLEDGEHUBTYPES':{
            return{
                ...state,
                knowlegeHubTypes:action.payload
            }
        }

        case Actions.FORUMLOADING_MSG:{
            return {
                ...state,
                deleteMsgLoading:true
            }
        }
        default:
            return state
    }
  }
  export default marketPlaceReducer