    const virtualExhibition = (state = {
        hall : null,
        exhibitorsData : [],
        showScroll:0
    }, action) => {
    switch (action.type) {
        case 'EXHIBITION_HALLS' :
            return{
                ...state,
                halls : action.payload
            }
        case 'EXHIBITOR_DATA_LOADED' :
            return{
                ...state,
                exhibitorsData : action.payload
            }

        case 'REMOVE_SCROLL':
            return {
                ...state,
                showScroll:action.payload
            }
        default:
            return state
        }
  }
  export default virtualExhibition