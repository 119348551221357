import { tr } from "date-fns/locale";
import { truncate } from "lodash";

export const Constants = {
    API_URL: 'http://localhost:8000/api/v1/',//local url
    //API_URL : 'https://eventmanagement.mwancloud.com/api/v1/',// test site url
    API_TOKEN: 'ux2Ipv0NLJ3T2oPZU0he7Qn99av8IkIA2r618VUr5vYLJObLzHTwW3mDOCzvb4NI',  // stagging token
    //API_TOKEN: 'C0ElwWj4Y2s7LZWMFHQ5Y980MPeFVZQzAO8cdUJzcYJkfsMP4AU3LRRFxc5mkzbE',  //  live token
    Highlight: 'Highlight',
    Counter: 'Counter',
    Blog: 'Blog',
    Additional: 'Additional',
    Testimonial: 'Testimonial',
    Gallary: 'Gallary',
    RECAPTCHA_KEY: '6LfZ5dIZAAAAAO2moIUS5yZSIOcmbIkR2uecR6r-',
    faceBookAppId: '1455766501261339',
    googleClientId: '179133222622-n3qpmqn7opssr4ido5nua10m3gdnafme.apps.googleusercontent.com',
    linkedInClientId: '78s4imgtunki77',
    linkedInClientSecret: 'V4nbvKPTDsyIYTcE',
    linkedInRedirectUri: '/linkedin',
    inPreviewEnv: window.location.origin.indexOf('preview') > -1 ? true : false,
    isPreview: 0,
    comingSoonText: '',
    zoomApiKey: 'MG8qW0BfQpSdGzPFL5XChA',
    zoomApiSecret: 'DPYR4smcHf7TCDfGdSjZBeAxhBu5CVwxpPGM',
    showVirtualZone: true,
    defaultCountry: {
        code: 'ae',
        dailCode: '+971'
    },
    showVideoCallButton: true,
    encyptionKey: 'mJO0NFz0X1',
    contentBlocked: false,
    portNo: 41000,
    socketUrl: process.env.REACT_APP_SOCKET_URL
};
