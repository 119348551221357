import React from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import App from '.';
import Schedule from '../Schedule';
import PageNotFound from '../404Component';

const Routes = (props) => {
    return (
        <Router>
            <LastLocationProvider>
                <Switch>
                    <Route exact path={"/"}>
                        <Schedule
                            //event={props.event}
                            agendaDetails={props?.agendaDetails}
                            selectedAgendaDetails={props?.selectedAgendaDetails}
                            headerMenuItems={props.headerMenuItems}
                            footerMenuItems={props.footerMenuItems}
                            socket={props.socket}
                            unReadCount={props.unReadMessageCount}
                            unReadNotificationsCount={props.unReadNotificationsCount}
                            notifications={props.allNotifications}
                            participants={props.participants}
                            onNotificationSeen={props.onNotificationSeen}
                            onClearNotifications={props.onClearNotifications}
                            virtualMenuItems={props.virtualMenuItems}
                            certificateCompletionTime={props.certificateCompletionTime}
                            availableLocales={props.availableLocales}
                            languageClick={props.languageClick}
                            onClickAccountItem={props.onClickAccountItem}
                            seoData={[]}
                            menuIcon={props?.menuIcon}
                            eventSponsors={props?.eventSponsors}
                            agendaTitles={props?.agendaTitles}
                        />
                    </Route>
                    <Route >
                        <PageNotFound
                            event={props.event}
                            headerMenuItems={props.headerMenuItems}
                            footerMenuItems={props.footerMenuItems}
                            availableLocales={props.availableLocales}
                            languageClick={props.languageClick}
                        />
                    </Route>
                </Switch>
            </LastLocationProvider>
        </Router>
    )
}
export default Routes