let initialState = {
    galleryImages:[]
}
const getGalleryImages = (state = initialState, action) => {
    switch (action.type) {
        case 'GALLERY_LOADED' : 
            return{
            ...state,
            galleryImages : action.payload.galleryImages,
            title:action.payload.galleryTitle
            }
        default:
            return state
        }
  }
  export default getGalleryImages